import 'tailwindcss/tailwind.css';
import '../styles/globals.css';
import React, { useEffect } from 'react';
import type { AppProps } from 'next/app';
import { isMobile } from '@/utils/isMobile';
import StyledComponentsRegistry from '../registry';
import { useUtmAttributes } from '@/hooks/useUtmAttributes';
import NextProgress from 'nextjs-progressbar';
import { COLORS } from '@/constants/colors';
import { QuidgetScript } from '@/components/QuidgetScript/QuidgetScript';

function MyApp({ Component, pageProps }: AppProps) {
  useUtmAttributes();

  useEffect(() => {
    if (isMobile()) {
      document.body.classList.add('mobile');
      document.body.classList.remove('desktop');
    } else {
      document.body.classList.remove('mobile');
      document.body.classList.add('desktop');
    }
  }, []);

  return (
    <StyledComponentsRegistry>
      <NextProgress
        color={COLORS.GREEN}
        startPosition={0.3}
        stopDelayMs={200}
        height={3}
        showOnShallow
      />
      <Component {...pageProps} />
      <QuidgetScript isMobile={isMobile} />
    </StyledComponentsRegistry>
  );
}

export default MyApp;
