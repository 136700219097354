const ROOTS_AUTH = '/auth';
const ROOTS_DEMO = '/demo';

export const PATH_AUTH_SPA = {
  ROOT: ROOTS_AUTH,
};

export const PATH_FAQ = {
  ROOT: '/faq',
};

export const PATH_DEMO = {
  ROOT: ROOTS_DEMO,
  CUSTOMER_DEMO: '/customer/demo',
  AGENT_DEMO: '/cosupport-agent/demo',
  BI_DEMO: '/cosupport-bi/demo',
  COSUPPORT_CUSTOMER_DEMO: '/cosupport-customer/demo',
  SCHEDULE_DEMO: '/schedule-a-demo',
};

export const PATH_INTEGRATION = {
  INTEGRATION: '/integrations',
  INTEGRATION_ZENDESK: '/integrations/zendesk',
  INTEGRATION_FRESHDESK: '/integrations/freshdesk',
};

export const PATH_ABOUT = {
  ROOT: '/about',
};
export const PATH_AGENT = {
  ROOT: '/cosupport-agent',
};
export const PATH_BI = {
  ROOT: '/cosupport-bi',
};
export const PATH_CUSTOMER = {
  ROOT: '/cosupport-customer',
};

export const PATH_ARTICLES = {
  ROOT: '/articles',
  AI_WINS: '/articles/klarna-ai-wins-big',
  WORLD_AWARDS: '/articles/cosupport-wins-ai-world-series-award-2024',
  PATENT: '/articles/cosupport-ai-patent',
};
export const PATH_PRIVACY = {
  ROOT: '/privacy',
  DPA: '/dpa',
  TERMS_OF_WEBSITE: '/terms-of-website',
  TERMS_OF_SERVICES: '/terms-of-services',
  SUB_PROCESSORS_LIST: '/sub-processors-list',
  RESELLER_TERMS: '/reseller-terms-of-services',
};
export const PATH_SUCCESS = {
  ROOT: '/success',
};
export const PATH_AUTHORS = {
  ROOT: '/authors',
};
export const PATH_TAGS = {
  ROOT: '/tags',
};
