import React, { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { PATH_ARTICLES, PATH_DEMO } from '@/constants/spa-routes';
import { useMediaQuery } from '@/hooks/useMediaQuery';

type QuidgetScriptProps = {
  quidgetSrc?: string;
  quidgetId?: string;
  quidgetOption?: string;
  isMobile?: () => boolean;
};

const quidgetSrcInit = 'https://w.quidget.ai/quidget.loader.js';
const quidgetIdInit = 'f6dba67c-5a2d-4f20-84f4-eb3ec9cc7669';
const defaultQuidgetOption = '{}';
const quidgetOptionDemo = `{"chatView":"permanent"}`;

export const QuidgetScript = ({
  quidgetSrc = quidgetSrcInit,
  quidgetId = quidgetIdInit,
  quidgetOption = defaultQuidgetOption,
}: QuidgetScriptProps) => {
  const scriptDivRef = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const is1Xl = useMediaQuery('(min-width: 1440px)');

  useEffect(() => {
    const articlesRefreshDesktop =
      is1Xl && router.pathname.includes(`${PATH_ARTICLES.ROOT}/`);

    const quidgetNode = document?.querySelector('#quidget');
    const range = document.createRange();
    const documentFragment = range.createContextualFragment(`<script
        async
        src=${quidgetSrc}
        data-id=${quidgetId}
        data-options=${router.pathname.includes(PATH_DEMO.CUSTOMER_DEMO) || articlesRefreshDesktop ? quidgetOptionDemo : quidgetOption}
      />`);
    if (
      scriptDivRef.current &&
      !scriptDivRef.current.innerHTML.includes('<script') &&
      !quidgetNode
    ) {
      scriptDivRef.current.append(documentFragment);
    }

    const existingScript: HTMLScriptElement | null = document.querySelector(
      'script[src="https://w.quidget.ai/quidget.loader.js"]',
    );

    const refreshHandler = (option: string) => {
      const script = document.createElement('script');
      script.async = true;
      script.src = quidgetSrc;
      script.setAttribute('data-id', quidgetId);
      script.setAttribute('data-options', option);

      if (existingScript) {
        existingScript.parentNode?.replaceChild(script, existingScript);
      }
    };

    if (
      router.pathname.includes(PATH_DEMO.CUSTOMER_DEMO) ||
      articlesRefreshDesktop
    ) {
      refreshHandler(quidgetOptionDemo);
    } else {
      if (existingScript?.dataset?.options !== defaultQuidgetOption) {
        refreshHandler(defaultQuidgetOption);
      }
    }
  }, [router, is1Xl]);

  return <div ref={scriptDivRef} />;
};
